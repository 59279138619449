@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.phoneField,
.codeValidationField {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.sendVerificationCodeWrapper {
  display: flex;
  margin-top: 8px;
  gap: 8px;
}

.sendCode {
  color: var(--colorSuccessDark);
  font-size: 13px;
}

.resendCode {
  color: var(--colorSuccessDark);
  font-size: 13px;
  text-decoration: underline;
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password,
.confirmationPassword {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}
.notificationWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 8px;
}
.error {
  color: var(--colorFail);
}

.spinner {
  stroke: var(--colorSuccessDark);
  margin: 0 12px;
}

.disableCode {
  color: var(--colorGrey400);
}

.success {
  margin: 0 4px 0 12px;
}

.successText {
  color: var(--colorSuccessDark);
}

.phoneVerifyFieldWrapper {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.sendCodeBtn {
  font-size: 14px;
  flex-basis: 40%;
  min-height: 36px;

  @media (--viewportMedium) {
    height: 48px;
  }
}
